.form-control {
    color: #27282a;
    background-color: #F3F3F3;
    border: none;
}

.form-control:focus {
    color: #27282a;
    background-color: #F3F3F3;
    border-color: #2146ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 111, 255, 0.25);
}

.error-field {
    color: #27282a;
    background-color: #F3F3F3;
    border-color: #ff214f;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 33, 79, 0.25);
}

.form-box {
    padding: 20px 15px 15px 15px;
    background-color: #FFFFFF;
    font-family: sans-serif;
}

.btn-primary {
    background-color: #FF4F66;
    border-color: #FF4F66;
    width: 150px;
}

div.btn-submit {
    justify-content: flex-end;
    display: flex;
    padding: 0;
}

.form-control::-webkit-input-placeholder {
    color: #27282a;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #27282a;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #27282a;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #27282a;
    opacity: 1;
}

.form-control::placeholder {
    color: #27282a;
    opacity: 1;
}

.form-sellos {
    margin-top: 2%;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
}

.form-present-box {
    padding-bottom: 1%;
    border-bottom: solid;
    border-color: #1d2124;
}

.form-material-box {
    padding-bottom: 5%;
    border-bottom: solid;
    border-color: #1d2124;
}

/*
 * this class is to position the div
 * when located inside cotizar-form.js
 */
.imagePlaceholder {
    margin-top: 50px;
    height: 1000px;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.3);
    background-color: rgba(215, 234, 250, 0.35);
}

.preview-image { height: 100%; }
.iso-cuadrado { padding-top: 14%; }
.iso-horizontal { padding-top: 18%; }
.iso-vertical { padding-top: 12%; }
.pl-image { padding-top: 22%; }
.pl-image-cuadro { padding-top: 18%; }
.pl-image-vertical { padding-top: 16%; }