.food {
    width: 100px;
}

.has-img-company {
    background: url(../assets/pics/nosotros/IMG_2826.jpg) no-repeat center center;
    background-size: 100%;
    height: 435px;
}

.has-img-mission {
    background: url(../assets/pics/nosotros/IMG_2855.jpg) no-repeat center center;
    background-size: 100%;
    height: 435px;
}

.has-img-vision {
    background: url(../assets/pics/nosotros/IMG_2861.jpg) no-repeat center center;
    background-size: 100%;
    height: 435px;
}

.target-bg {
    background-image: url(../assets/icon/target.png);
    background-repeat: no-repeat;
    background-size: 250px;
    background-position-x: right;
    -ms-background-position-x: right;
    height: 300px;
}

.bulb-bg {
    background-image: url(../assets/icon/bulb.png);
    background-repeat: no-repeat;
    background-size: 250px;
    background-position-x: left;
    -ms-background-position-x: left;
    height: 300px;
}

.title-card {
    font-size: 25px;
    font-weight: bold;
    padding: 20px 0 25px 0;
}

.border-card {
    border-radius: 20px;
    margin: 15px 15px 15px 15px;
}

.shadow-card {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, .3) !important;
}

.card-row {
    justify-content: center;
    margin-bottom: 35px;
    height: 350px;
}

.rightControl {
    cursor: pointer;
}

.leftControl {
    cursor: pointer;
}