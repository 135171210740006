@import "nosotros.css";
@import "common.css";
@import "contact.css";
@import "footer.css";
@import "products.css";
@import "cover-images.css";

.navbar-brand .brand-img {
  width: 45px;
}

.affix .navbar-brand .brand-img {
  -ms-transform: none;
  transform: none;
  opacity: 1;
  visibility: visible;
  width: 45px;
}

.custom-navbar {
  background-color: white;
  border-bottom: solid 1px;
  min-height: 110px;
  box-shadow: 6px 6px 18px 0 rgba(0,0,0,0.3);
}

.navbar-brand {
  padding-right: 2rem;
  text-align: right;
}

.header-icon {
  width: 22px;
}

.heroImage {
  width: -webkit-fill-available;
  background-size: cover;
  height: 750px;
}

.has-img-about {
  background: url(../assets/pics/home/IMG_2982.jpg) no-repeat center center;
  background-size: 100%;
  height: 435px;
}

.has-img-products {
  background: url(../assets/pics/home/IMG_3186.jpg) no-repeat center center;
  background-size: 100%;
  height: 435px;
}

.title-right {
  text-align: end;
  justify-content: end;
  padding-right: 5%;
  padding-bottom: 10px;
}

.title-center {
  text-align: center;
  justify-content: center;
  padding: 25px 0 10px 0;
}

.title-left {
  text-align: start;
  justify-content: start;
  padding-left: 5%;
  padding-bottom: 10px;
}

body {
  margin: 0;
  font-family: Poppins,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
  color: #1b1e21;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}