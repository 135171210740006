.has-img-our-products {
    background: url(../assets/pics/products/IMG_5023.jpg) no-repeat center center;
    background-size: 100%;
    background-position-y: bottom;
    height: 450px;
}

.has-img-extrucion {
    background: url(../assets/pics/products/IMG_5043.jpg) no-repeat center center;
    background-size: 100%;
    background-position-y: center;
    height: 450px;
}

.has-img-bolseo {
    background: url(../assets/pics/products/IMG_5932.jpg) no-repeat center center;
    background-size: 100%;
    background-position-y: top;
    height: 450px;
}

.has-img-variables {
    background: url(../assets/pics/products/IMG_5849.jpg) no-repeat center center;
    background-size: 100%;
    background-position-y: top;
    height: 450px;
}