.header .overlay { background: rgba(33, 37, 41, 0.1); }

.header-bg-cover-1 {
    background: url(../assets/pics/home/IMG_2532.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-bg-cover-2 {
    background: url(../assets/pics/home/extrusor.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-bg-cover-3 {
    background: url(../assets/pics/home/Rectangle54.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-bg-cover-4 {
    background: url(../assets/pics/products/IMG_5389.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-bg-cover-5 {
    background: url(../assets/pics/cotizador/IMG_2999.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@supports (-webkit-touch-callout: none) { /* support for ios devices */
    .header-bg-cover-1 {
        background: url(../assets/pics/home/IMG_2532.jpg) center top no-repeat scroll;
        background-size: auto 100%;
    }

    .header-bg-cover-2 {
        background: url(../assets/pics/home/extrusor.jpg) center top no-repeat scroll;
        background-size: auto 100%;
    }

    .header-bg-cover-3 {
        background: url(../assets/pics/home/Rectangle54.jpg) center top no-repeat scroll;
        background-size: auto 100%;
    }

    .header-bg-cover-4 {
        background: url(../assets/pics/products/IMG_5389.jpg) center top no-repeat scroll;
        background-size: auto 100%;
    }

    .header-bg-cover-5 {
        background: url(../assets/pics/cotizador/IMG_2999.jpg) center top no-repeat scroll;
        background-size: auto 100%;
    }
}
