.bg-blue { background-color: #143969; }

.box-shadow-overlay { box-shadow: 6px 6px 18px 0 rgba(0,0,0,0.3); }

.pad-lr-0 {
    padding-left: 0;
    padding-right: 0;
}

.pad-lr-5 {
    padding-left: 2.5% !important;
    padding-right: 2.5% !important;
}

.pad-65 {
    padding-top: 6%;
    padding-right: 6%;
}

.p-20 { padding: 20px 20px 20px 20px; }
.p-25 { padding: 20px 20px 10px 25px; }

.pr-30 { padding-right: 30px; }

.pl-30 { padding-left: 30px; }

.pt5 { padding-top: 5px; }

.ptb-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.mart-15 { margin-top: 15%; }

.mar-35lr { margin: 0 35px 0 35px; }

.justify { text-align: justify; }

.hr-line-right {
    border: solid;
    color: #143969;
    position: relative;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}

.hr-line-left {
    border: solid;
    color: #143969;
    position: relative;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}

.col-sm-45 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 36.7%;
    -ms-flex: 0 0 36.7%;
    flex: 0 0 36.7%;
    max-width: 36.7%;
}

.row {
    /* this class fixes adding horizontal scroll */
    margin-right: 0;
    margin-left: 0;
}
h2, h3, h4, h5 {
    color: #143969;
}

h2, h3, h4, h5, h6 {
    font-family: Poppins, sans-serif;
    font-weight: 800;
}

h2 { font-size: 30px; }
h3 { font-size: 25px; }
h4 { font-size: 20px; }
h5 { font-size: 15px; }
h6 { font-size: 10px; }

.product > ul { text-align: start; }

.align-start { text-align: start; }

.align-end { text-align: end; }

.marl-5 { margin-left: -5%; }

.marr-5 { margin-right: -5%; }

.width-25 { width: 25%; }

.width-30 { width: 30%; }

.width-40 { width: 40%; }

.width-50 { width: 50%; }

.width-75 { width: 85%; }

.marl-25 { margin-left: 25%; }

.marl-125 { margin-left: 7.5%; }

.alert-warning { background-color: #fffcc0; }

.alert-success { background-color: #caf1cc}