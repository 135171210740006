.footer-icon {
    width: 34px;
}

.footer-logo {
    width: 150px;
    margin-top: -10px;
}

.footer-atmx a {
    color: white;
}

.footer-atmx p {
    font-size: 13px;
    text-align: left;
}

.footer-atmx h6 {
    font-size: 24px;
    text-align: left;
}

.footer-atmx .aviso {
    margin-top: 165px;
    text-align: right;
    padding-right: 50px;
}